import React from "react"
import PageHeader from "./header"

function About() {
    return (
    <div>
        <PageHeader/>
        <div>Test</div>
    </div>
    )
}

export default About